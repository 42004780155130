const FileDropZone = ({ onFileSelect }) => {
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      onFileSelect(file);
    } else {
      alert('Please select a PDF file');
    }
  };

  return (
    <div className="w-full">
      <input
        type="file"
        id="file-input"
        accept=".pdf"
        onChange={handleFileChange}
        className="hidden"
      />
      
      <label 
        htmlFor="file-input" 
        className="group relative w-full max-w-2xl mx-auto p-8 flex flex-col items-center justify-center 
                 min-h-[300px]
                 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 
                 rounded-xl shadow-xl cursor-pointer
                 transform transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl"
      >
        <div className="absolute inset-0 bg-white opacity-90 rounded-xl group-hover:opacity-80 transition-opacity" />
        
        <div className="relative space-y-6 text-center">
          {/* Updated upload icon */}
          <svg 
            className="mx-auto h-16 w-16 text-indigo-600 group-hover:scale-110 transition-transform duration-300"
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>

          <div>
            <h3 className="text-2xl font-bold text-gray-800 group-hover:text-gray-900">
              Upload Your PDF
            </h3>
            <p className="mt-2 text-gray-600 group-hover:text-gray-700">
              Click to browse or drag and drop your file
            </p>
            <p className="mt-1 text-sm text-gray-500">
              PDF files only
            </p>
          </div>
        </div>
      </label>
    </div>
  );
};

export default FileDropZone; 