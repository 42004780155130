import React, { memo } from 'react';
import AudioPlayer from './AudioPlayer';

const AudioFilesList = memo(({ audioFiles, onDownload }) => {
  const mostRecentAudio = audioFiles[audioFiles.length - 1];

  return (
    <div>
      {mostRecentAudio && (
        <div className="py-4 hover:bg-gray-50/50 transition-colors">
          <AudioPlayer
            audioId={mostRecentAudio.id}
            filename={mostRecentAudio.filename}
          />
        </div>
      )}
    </div>
  );
});

AudioFilesList.displayName = 'AudioFilesList';

export default AudioFilesList; 